import type { HomeBreadCrumbItem } from "~/components/HomeBreadCrumbs.vue"
import { useI18n } from "vue-i18n"
import {
    SUPPORTED_BREAD_CRUMBS_VALUES,
    type SUPPORTED_PAGES,
    SUPPORTED_PAGES_ENUM,
} from "~/common/breadCrumbs"

export interface HomeBreadCrumbs {
    name: SUPPORTED_PAGES
    breadCrumbs: HomeBreadCrumbItem[]
}

export function useBreadCrumbs(currentPage?: SUPPORTED_PAGES) {
    const i18n = useI18n()
    const parsedBreadCrumbsToLayout = SUPPORTED_BREAD_CRUMBS_VALUES.map(
        (item) => {
            return {
                ...item,
                breadCrumbs: item.breadCrumbs.map((item) => {
                    return {
                        ...item,
                        name: i18n.t(item.name),
                    }
                }),
            }
        }
    )

    function generateBreadCrumbs(
        currentPage?: SUPPORTED_PAGES
    ): HomeBreadCrumbItem[] {
        const breadCrumbs = parsedBreadCrumbsToLayout.find(
            (i) => i.name === currentPage
        )?.breadCrumbs
        return breadCrumbs || parsedBreadCrumbsToLayout[0].breadCrumbs
    }

    const breadCrumbs = ref<HomeBreadCrumbItem[]>(
        currentPage ? generateBreadCrumbs(currentPage) : []
    )
    const setCurrentBreadCrumb = () => {
        breadCrumbs.value = breadCrumbs.value.map((item, index) => {
            if (index == breadCrumbs.value.length - 1) {
                return { ...item, isCurrent: true }
            }
            return { ...item, isCurrent: false }
        })
    }

    const onCreate = () => {
        setCurrentBreadCrumb()
        return breadCrumbs
    }

    const addBreadCrumbsItem = (name: string) => {
        breadCrumbs.value = breadCrumbs.value.map((breadCrumb, i, array) => {
            if (i === array.length - 1) {
                return { name: name }
            }
            return breadCrumb
        })
        setCurrentBreadCrumb()
    }

    const build = (newBreadCrumbs: HomeBreadCrumbItem[]) => {
        breadCrumbs.value = newBreadCrumbs
        setCurrentBreadCrumb()
    }

    const pushBreadCrumb = (newBreadCrumbsItem: HomeBreadCrumbItem) => {
        breadCrumbs.value.push(newBreadCrumbsItem)
        setCurrentBreadCrumb()
    }

    const updateBreadCrumbs = (
        key: number,
        newBreadCrumbsItem: HomeBreadCrumbItem
    ) => {
        breadCrumbs.value = breadCrumbs.value.map((item, index) => {
            if (index == key) {
                return newBreadCrumbsItem
            }

            return item
        })

        setCurrentBreadCrumb()
    }

    const removeBreadCrumb = (key: number) => {
        breadCrumbs.value = breadCrumbs.value.filter(
            (item, index) => index != key
        )
        setCurrentBreadCrumb()
    }

    const addSomeBreadCrumbItems = (newBreadCrumbsItems: HomeBreadCrumbItem[]) => {
        if (!currentPage || currentPage === SUPPORTED_PAGES_ENUM.Home) {
            breadCrumbs.value = newBreadCrumbsItems;
            setCurrentBreadCrumb();
            return;
        }
        
        const defaultBreadCrumbs = generateBreadCrumbs(currentPage);
        
        if (currentPage === SUPPORTED_PAGES_ENUM.ShopDetails) {
            const homeBreadcrumb = defaultBreadCrumbs[0];
            breadCrumbs.value = [homeBreadcrumb, ...newBreadCrumbsItems];
        } else if (defaultBreadCrumbs.length > 1) {
            const baseBreadcrumbs = defaultBreadCrumbs.slice(0, defaultBreadCrumbs.length - 1);
            breadCrumbs.value = [...baseBreadcrumbs, ...newBreadCrumbsItems];
        } else {
            breadCrumbs.value = [...defaultBreadCrumbs, ...newBreadCrumbsItems];
        }
        
        setCurrentBreadCrumb();
    }

    return {
        breadCrumbs: onCreate(),
        generateBreadCrumbs,
        build,
        addBreadCrumbsItem,
        addSomeBreadCrumbItems,
        setCurrentBreadCrumb,
        updateBreadCrumbs,
        pushBreadCrumb,
        removeBreadCrumb,
    }
}
