<script setup lang="ts">
import type { Hours } from "~/models/Hours"
import type { Shop } from "~/models/Shop"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const shopListStore = useShopListStore()
const i18n = useI18n()
/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
function openNetwork(url: string) {
    window.open(url)
}

function scheduleHours(hours: Hours[]): string {
    return hours.map((i) => i.timeInit + " - " + i.timeEnd).join("/")
}

function scheduleDays(days: number[]): string {
    const daysNames = [
        i18n.t("week_days.monday"),
        i18n.t("week_days.tuesday"),
        i18n.t("week_days.wednesday"),
        i18n.t("week_days.thursday"),
        i18n.t("week_days.friday"),
        i18n.t("week_days.saturday"),
        i18n.t("week_days.sunday"),
    ]

    if (days.length === 0) return ""

    days.sort((a, b) => a - b)

    let rangeString = ""
    let start = days[0]
    let end = days[0]

    for (let i = 1; i < days.length; i++) {
        if (days[i] === end + 1) {
            end = days[i]
        } else {
            if (start === end) {
                rangeString += daysNames[start - 1] + ", "
            } else {
                rangeString +=
                    daysNames[start - 1] + " - " + daysNames[end - 1] + ", "
            }
            start = days[i]
            end = days[i]
        }
    }

    if (start === end) {
        rangeString += daysNames[start - 1]
    } else {
        rangeString += daysNames[start - 1] + " - " + daysNames[end - 1]
    }

    return rangeString
}

function storeBrands(shop: Shop): { url: string; icon: string }[] {
    let networks = []
    if (shop.contacts.facebook) {
        networks.push({
            url: shop.contacts.facebook,
            icon: "fa-brands fa-facebook",
        })
    }
    if (shop.contacts.whatsapp) {
        networks.push({
            url: shop.contacts.whatsapp,
            icon: "fa-brands fa-whatsapp",
        })
    }
    if (shop.contacts.instagram) {
        networks.push({
            url: shop.contacts.instagram,
            icon: "fa-brands fa-instagram",
        })
    }

    return networks
}
</script>

<template>
    <div v-if="shopListStore.currentShop" class="tw-bg-white tw-w-full">
        <div
            class="max-container tw-w-full tw-flex tw-flex-col tw-py-16 tw-gap-6"
        >
            <h2
                class="tw-font-semibold tw-text-[32px] tw-leading-[48px] tw-text-customGray-700"
            >
                {{ $t("stores.info_title") }}
                {{ shopListStore.currentShop.name }}
            </h2>
            <p
                class="tw-text-customGray-500"
                v-if="shopListStore?.currentShop?.informations.enable_about_us"
            >
                {{ shopListStore?.currentShop?.informations?.about_us }}
            </p>
            <p
                v-if="shopListStore?.currentShop?.url"
                class="tw-text-customGray-500"
            >
                {{ $t("stores.more_info_title") }}
                <a :href="shopListStore?.currentShop?.url">{{
                    shopListStore?.currentShop?.url
                }}</a>
            </p>
            <div
                v-if="
                    shopListStore?.currentShop?.informations
                        .enable_return_policy
                "
            >
                <q-separator class="tw-text-customGray-200" />
                <FooterStoreInformationItem
                    :content="
                        shopListStore?.currentShop?.informations.return_policy
                    "
                    :title="$t('stores.settings.customization.return_policy')"
                ></FooterStoreInformationItem>
            </div>
            <div
                v-if="
                    shopListStore?.currentShop?.informations
                        .enable_warranty_policy
                "
            >
                <q-separator class="tw-text-customGray-200" />
                <FooterStoreInformationItem
                    :content="
                        shopListStore?.currentShop?.informations.warranty_policy
                    "
                    :title="$t('stores.settings.customization.warranty_policy')"
                ></FooterStoreInformationItem>
            </div>
            <div
                v-if="
                    shopListStore?.currentShop?.informations
                        .enable_shipment_policy
                "
            >
                <q-separator class="tw-text-customGray-200" />
                <FooterStoreInformationItem
                    :content="
                        shopListStore?.currentShop?.informations.shipment_policy
                    "
                    :title="$t('stores.settings.customization.shipment_policy')"
                ></FooterStoreInformationItem>
            </div>
            <div v-if="shopListStore?.currentShop?.contacts">
                <q-separator class="tw-text-customGray-200" />
                <div class="tw-flex tw-justify-around tw-w-full tw-py-8">
                    <div
                        class="tw-flex tw-flex-col tw-bg-white tw-gap-4 tw-text-customGray-700"
                    >
                        <h6 class="tw-text-customGray-500">
                            {{ $t("social.contacts") }}:
                        </h6>
                        <div>
                            <q-icon
                                name="fa-solid fa-location-dot"
                                color="gray"
                            />
                            <span class="tw-mx-1"
                                >{{ $t("fields.address") }}:
                            </span>
                            <span
                                @click="
                                    openNetwork(
                                        'http://www.google.com/maps/place/' +
                                            shopListStore?.currentShop?.contacts
                                                ?.latitude +
                                            ',' +
                                            shopListStore?.currentShop.contacts
                                                ?.longitude
                                    )
                                "
                            >
                                {{
                                    shopListStore?.currentShop?.contacts
                                        ?.address
                                }}
                            </span>
                        </div>
                        <div>
                            <q-icon name="fa-solid fa-phone" color="gray" />
                            <span class="tw-mx-1"
                                >{{ $t("fields.phones") }}:
                            </span>
                            {{
                                Array.isArray(
                                    shopListStore?.currentShop?.contacts?.phones
                                )
                                    ? shopListStore?.currentShop?.contacts?.phones.join(
                                          ", "
                                      )
                                    : (shopListStore?.currentShop?.contacts
                                          ?.phones ?? "")
                            }}
                        </div>
                        <div>
                            <q-icon name="fa-solid fa-envelope" color="gray" />
                            <span class="tw-mx-1"
                                >{{ $t("fields.email") }}:
                            </span>
                            {{ shopListStore?.currentShop?.contacts?.email }}
                        </div>
                        <Brands
                            v-if="
                                storeBrands(shopListStore?.currentShop).length >
                                0
                            "
                            :networks="storeBrands(shopListStore?.currentShop)"
                            color="black"
                            size="24px"
                        ></Brands>
                    </div>
                    <div
                        v-if="shopListStore?.currentShop?.contacts?.schedule"
                        class="tw-flex tw-flex-col tw-gap-4 tw-text-customGray-700"
                    >
                        <h6 class="tw-text-customGray-500">
                            {{ $t("fields.schedule") }}:
                        </h6>
                        <span
                            class="tw-flex tw-gap-2"
                            v-for="schedule in shopListStore?.currentShop
                                ?.contacts?.schedule"
                        >
                            <span class="tw-font-bold tw-flex tw-gap-1"
                                >{{ scheduleDays(schedule.day_week) }}: </span
                            ><span>{{ scheduleHours(schedule.hours) }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.prefooter-container::before {
    content: "";
    width: 500px;
    position: absolute;
    left: 0;
    bottom: 0;
    aspect-ratio: 1 / 1;
    background: url("/img/banner-img.svg") no-repeat;
}
</style>
